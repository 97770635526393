import React, {Fragment, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {isEmpty} from "../components/Utils";
import VideoJS from "./VideoPlayer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {MyContext} from "../contexts/MyContext";
import {Redirect} from "react-router";
import {clearEvent, getEvent} from "../actions/events.action";
import {Helmet} from "react-helmet-async";
import { toggleModal } from "../actions/modal.action";
import Stripe from "../components/Stripe";
import {useMediaQuery} from "react-responsive";
import moment from "moment/moment";

const shareFb= 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href);
const shareTw= 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href);

const Live = ({ match }) => {

	const {
		params: { evtID },
	} = match;

	const dispatch = useDispatch();
	const history = useHistory();
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const eventReducer = useSelector(
		(state) => state.eventsReducer.getEventReducer
	);
	const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
	const [open, setOpen]=useState(false);
	const [live, setLive] = useState();
	const [state, setState] = useState(null);
	const [playerClass, setPlayerClass] = useState(null);
	const [ads, setAds] = useState(null);
	const [needPay, setNeedPay] = useState(false);
	const [needPayMinHierarchy, setNeedPayMinHierarchy] = useState(1);
	const { rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);
	const [openModal, setOpenModal] = useState(false);
	const [styles, setStyles] = useState({});

	useEffect(() => {
		setOpenModal(live?.adult_content === "yes" && live?.started === "yes" && localStorage.getItem(`adultContentLive_${live?.id}`) !== "yes")
	}, [live]);

	useEffect(() => {
		if (settings?.videodescr_bg_color) {
			setStyles((prevState) => ({...prevState, backgroundColor: settings.videodescr_bg_color}));
		}
		if (settings?.videodescr_text_color) {
			setStyles((prevState) => ({...prevState, color: settings.videodescr_text_color}));
		}
	}, [settings]);

	useEffect(() => {
		async function auth() {
			if (isAuth) {
				localStorage.removeItem('loginRedirectUrl');
			} else {
				localStorage.setItem('loginRedirectUrl', window.location);
				dispatch(getEvent(evtID));
			}

		}
		if (isAuth !== null) {
			auth();
		}
		// eslint-disable-next-line
	}, [isAuth]);

	useEffect(() => {
		if (eventReducer.result && eventReducer.code === 401) {
			setState(false)
		} else if (!isEmpty(eventReducer.event) && !isEmpty(eventReducer.event[0])) {
			setLive(eventReducer.event[0]);
			setAds(eventReducer.event[0]?.ads?.data);
		} else {
			setLive(null);
			setAds(null);
		}
	}, [eventReducer]);

	useEffect(() => {
		dispatch(clearEvent());
		dispatch(getEvent(evtID));
		setNeedPay(false);
	}, [dispatch, evtID]);

	useEffect(() => {
		return () => {
			dispatch(clearEvent());
		};
		// eslint-disable-next-line
	}, []);

	//check if player ready and initial datas
	useEffect(() => {
		if (live?.lttp === 'youtube') return;
		if (!isEmpty(playerClass) && playerClass.isDisposed_ !== true) {
			if (!isEmpty(ads)) {
				playerClass.fullscreenIphone();
			}
			playerClass.preroll()
				.preRollUpdate(
					!isEmpty(ads?.videos) && (!isEmpty(ads?.activated.AD_PRE_RL) && ads?.activated.AD_PRE_RL === true) ?
						ads?.videos :
						{}
				);

			playerClass.sponsors()
				.sponsorUpdate(
					!isEmpty(ads?.sp_banner) && (!isEmpty(ads?.activated.AD_SP_BNR) && ads?.activated.AD_SP_BNR === true) ?
						{...ads.sp_banner, displayTime: 5} :
						{
							background: null,
							headings: null,
							logos: null,
							sponsors: null,
							displayTime: 5
						});

			playerClass.pills()
				.updateState(
					!isEmpty(ads?.pills) && (!isEmpty(ads?.activated.AD_TBL_LG) && ads?.activated.AD_TBL_LG === true) ? ads?.pills :
						{
							hide_animation: null,
							images: [],
							secondary_display_time: null,
							secondary_times: null,
							show_animation: null

						});

			playerClass.toasts()
				.toastsUpdate(!isEmpty(ads?.toasts) && (!isEmpty(ads?.activated.AD_UP_TST) && ads?.activated.AD_UP_TST === true) ?
					ads?.toasts :
					{
						primary: null,
						secondary: null,
						secondary_display_time: null,
						secondary_hide_animation: null,
						secondary_show_animation: null,
						secondary_times: null,
					});

			playerClass.watermark()
				.updateState(
					!isEmpty(ads?.watermark) && (!isEmpty(ads?.activated.AD_WTRMRK) && ads?.activated.AD_WTRMRK === true) ?
						{
							image: ads?.watermark,
							position: ads?.watermark_position,
							fadeTime: null
						} :
						{
							image: null,
							position: null,
							fadeTime: null
						});

			playerClass.on('needToPay', (event, status) => needToPay(event, status))

			handleInitPlayerReady();

		}
		// eslint-disable-next-line
	}, [ads, playerClass]);

	const needToPay = (event, status) => {
		setNeedPayMinHierarchy(live.subscription.min_hierarchy)
		setNeedPay(status);
	}

	const handlePlayerReady = (player) => {
		setPlayerClass(player);
	};


	//start after init player and implemented ads
	const handleInitPlayerReady =() => {
		if (playerClass && playerClass.isDisposed_ !== true) {
			if (ads?.videos && Object.keys(ads?.videos).length > 0 && playerClass.preroll().ended === false) {
				playerClass.watermark().hideWaterMark();
				playerClass.toasts().toastsStop();
				playerClass.pills().pillsStop();
				playerClass.one("endallpreroll",  () => {
					if (!isEmpty(ads?.sp_banner) && ads?.activated.AD_SP_BNR === true) {
						playerClass.sponsors().startSponsors();
						playerClass.one("sponsorstop", () => {
							initPlayer();
						})
					} else {
						initPlayer();
					}
				})
			} else {
				if (!isEmpty(ads?.sp_banner) && ads?.activated.AD_SP_BNR === true) {
					playerClass.watermark().hideWaterMark();
					playerClass.toasts().toastsStop();
					playerClass.sponsors().startSponsors();
					playerClass.one("sponsorstop", () => {
						initPlayer();
					})
				} else {
					initPlayer();
				}
			}
		}
	};

	//start default player
	const initPlayer = () => {
		if (live.started === "yes" && (live.url.high || live.url.med || live.url.low) && live?.lttp !== 'youtube') {
			playerClass.updateSrc([
				{
					src: live.url.high ? live.url.high : null,
					type: 'application/x-mpegURL',
					label: 'HIGH',
					res: 1080
				},
				{
					src: live.url.med ? live.url.med : null,
					type: 'application/x-mpegURL',
					label: 'MED',
					res: 720
				},
				{
					src: live.url.low ? live.url.low : null,
					type: 'application/x-mpegURL',
					label: 'LOW',
					res: 360
				}
			]);
		}

		playerClass.subscription().updateState({
			can_view: live.subscription?.can_view,
			entity: "live"
		});
	};

	const handleCopy = () => {
		let	text = translations && !isEmpty(translations["copy_clipboard"])
		? translations["copy_clipboard"]
		: "Copy in clipboard !"

		dispatch(toggleModal({opened: true, success: true, text: text}))
	};

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/`);
		}
	};

	return (
		<div>
			{!isEmpty(live) && (
				<Helmet>
					<title>{`${process.env.REACT_APP_CLIENT_NAME} - ${live.title}`}</title>
					<meta name="title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${live.title}`} />
					<meta property="og:title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${live.title}`} />
					<meta property="og:description" content={`${live.description}`} />
					<meta property="og:image" content={`${live.pic_desktop_small}`} />
				</Helmet>
			)}

			{state === false && <Redirect to="/account/login" />}
			<div id="video_player_bg"/>
			<div className="player-page container">
				<div className="my-3 d-flex">
					<div className="d-flex cursor-pointer" onClick={() => clickBack()}>
						<span className="back">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" fill="none"><circle cx="44" cy="44" r="42" strokeWidth="4" stroke={settings?.general_text_color ? settings.general_text_color : "white"}/><path d="M47.6,60.9L31.5,46.2c-0.7-0.7-1.1-1.4-1.1-2.2c0-0.8,0.4-1.6,1.1-2.2l16.1-14.7c0.5-0.5,1.2-0.8,2-0.8 c1.6,0,2.9,1.2,2.9,2.7c0,0.7-0.3,1.4-0.9,2L37.3,44l14.3,13c0.6,0.5,0.9,1.2,0.9,2c0,1.5-1.3,2.7-2.9,2.7 C48.8,61.7,48.1,61.4,47.6,60.9z" fill={settings?.general_text_color ? settings.general_text_color : "white"}/></svg>
						</span>
						{/*<span className="d-flex my-auto">
							{translations && !isEmpty(translations["back"])
								? translations["back"]
								: "back"}
						</span>*/}
					</div>
				</div>
				<div className="d-flex">
					{!isEmpty(live) && (
						<Fragment>
							{openModal && (
								<Fragment>
									<div className="background position-absolute top-left bottom-right" style={{zIndex: "9", backgroundColor: "rgb(0 0 0 / 51%)"}}/>

									<div className="absolute-center modal p-4" style={
										{...styles, ...{zIndex: 10, width: isMobile ? "80%" : "", lineHeight: "2.2rem", backgroundColor: settings?.accent_color}
										}}
									>
										<div className="d-flex flex-column p-4" style={{fontSize: "120%"}}>
											<p className="w-100">
												{translations && !isEmpty(translations["adult_content_text"])
													? translations["adult_content_text"]
													: "Content reserved for adults. Confirm that you are over 18 years old."
												}
											</p>
											<div className="d-flex w-100 mt-4 justify-content-end">
												<button className="button button-template small mr-2"
												        onClick={() => {
													        localStorage.setItem(`adultContentLive_${live?.id}`, "yes");
															setOpenModal(false)
												        }}
												        style={{border: "none", backgroundColor: settings?.accent_color}}
												>{translations && !isEmpty(translations["yes"]) ? translations["yes"] : "yes"}</button>
												<button className="button button-template small"
												        onClick={() => history.push(`/`)}
												        style={{border: "none", backgroundColor: settings?.accent_color}}
												>{translations && !isEmpty(translations["no"]) ? translations["no"] : "no"}</button>
											</div>
										</div>
									</div>
								</Fragment>
							)}

							<div className="video-iframe" id="video">
								<div className="video position-relative" style={openModal ? {filter: "blur(40px)"} : {}}>
									{needPay ?
										(
											<Stripe
												minHierarchy={needPayMinHierarchy}
												poster={live ? live.pic_desktop_big : ""}
												entityType={'live'}
												entityId={live?.id}
												priceForView={live?.price_per_view}
											/>
										) : (live?.lttp === 'youtube' ?
											<iframe
												width="100%"
												height="100%"
												src={`https://www.youtube.com/embed/${live?.url?.yt}`}
												title="YouTube video player"
												frameBorder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												referrerPolicy="strict-origin-when-cross-origin"
												allowFullScreen
											></iframe>
											:
											<VideoJS
												options={
													{
														autoplay: false,
														controls: true,
														responsive: true,
														playsinline: true,
														fill: true,
														videoId: null,
														liveId: evtID,
														userActions: {
															doubleClick: false
														},
														poster: live ? live.pic_desktop_big : "",
														plugins: {
															videoJsResolutionSwitcher: {
																default: isMobile ? 360 :720,
																dynamicLabel: true
															},
														}
													}
												}
												onReady={handlePlayerReady}
											/>
										)
									}

								</div>
								<div className="video-data" style={styles}>

									<div className="first-row">
										<h2>{!isEmpty(live) && live.title}</h2>

										<div className="views-block start-date">
											{moment(live.start).format("DD/MM/YYYY") === moment(live.end).format("DD/MM/YYYY") ? (
												`${moment(live.start).format("DD/MM/YYYY HH:mm")} - ${moment(live.end).format("HH:mm")}`
											) : (
												`${moment(live.start).format("DD/MM/YYYY HH:mm")} - ${moment(live.end).format("DD/MM/YYYY HH:mm")}`
											)}

										</div>
									</div>

									<div className="description">
										<div className="scroll-wrap">
											{live.description}
										</div>
									</div>
									<div className="buttons" id="share-live">
										<div className={open ? "share-open": "share-closed"}>
											<button className="button button-blue shared" id="share_btn" onClick={() => setOpen(!open)}>{
												translations && !isEmpty(translations["share_video"])
													? translations["share_video"]
													: "share_video"
											}
												<svg id="arrow-menu"  width="4" height="7" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.05273 13.5577C1.346 13.5577 1.5791 13.4524 1.78213 13.2569L7.54961 7.6248C7.80527 7.36914 7.92559 7.10596 7.93311 6.78262C7.93311 6.45928 7.81279 6.18857 7.54961 5.94043L1.78213 0.300781C1.5791 0.105273 1.33848 0 1.05273 0C0.466211 0 0 0.466211 0 1.04521C0 1.33096 0.120313 1.60166 0.330859 1.81221L5.44414 6.78262L0.330859 11.7455C0.120313 11.9561 0 12.2268 0 12.5125C0 13.0915 0.466211 13.5577 1.05273 13.5577Z" fill="white"/></svg>
											</button>
											<ul className="share-sub"
											    style={{backgroundColor: settings?.accent_color}}
											>
												<li>
													<a href={shareFb} target="_blank" rel="noopener noreferrer">Facebook</a>
												</li>
												<li>
													<a href={shareTw} target="_blank" rel="noopener noreferrer">Twitter</a>
												</li>
												<li>
													<CopyToClipboard onCopy={handleCopy} text={window.location.href}>
														<button className="copy-link">Copy Link</button>
													</CopyToClipboard>
												</li>
											</ul>
										</div>

									</div>
								</div>
							</div>
						</Fragment>
					)}
				</div>

			</div>
		</div>
	);
};

export default Live;